var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"title":"编辑","visible":_vm.dialogVisible,"width":"800px","close-on-click-modal":false,"append-to-body":""},on:{"update:visible":function($event){_vm.dialogVisible=$event},"close":_vm.onDialogClose}},[_c('el-form',{ref:"form",staticClass:"multiple-ruleForm",attrs:{"model":_vm.form,"label-width":"100px","label-position":"top","size":"small","disabled":_vm.submitting}},[_c('el-row',{attrs:{"gutter":10}},_vm._l((_vm.form.items),function(item,index){return _c('el-col',{key:item.key,attrs:{"sm":24,"md":24}},[_c('el-card',{staticClass:"box-card",attrs:{"shadow":"never","body-style":{
            padding: ' 0 10px',
          }}},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_vm._v(" 条目"+_vm._s(index + 1)+" "),_c('el-button',{staticClass:"text-danger",staticStyle:{"float":"right","padding":"3px 0"},attrs:{"type":"text","disabled":_vm.submitting || _vm.form.items.length <= 1},on:{"click":function($event){return _vm.onDeleteRow(index)}}},[_vm._v("删除")])],1),_c('el-row',{attrs:{"gutter":10}},[_c('el-col',{attrs:{"sm":24,"md":6}},[_c('el-form-item',{attrs:{"label-position":"left","label":"中文名称","prop":'items.' + index + '.name.cn',"rules":[
                  {
                    required: true,
                    message: '请输入中文名称',
                    trigger: 'blur',
                  },
                ]}},[_c('el-input',{ref:`input${index}`,refInFor:true,attrs:{"disabled":_vm.submitting,"clearable":"","placeholder":"请输入"},model:{value:(item.name.cn),callback:function ($$v) {_vm.$set(item.name, "cn", $$v)},expression:"item.name.cn"}})],1)],1),_c('el-col',{attrs:{"sm":24,"md":6}},[_c('el-form-item',{attrs:{"label-position":"left","label":"英文名称","prop":'items.' + index + '.name.en',"rules":[
                  {
                    required: true,
                    message: '请输入英文名称',
                    trigger: 'blur',
                  },
                ]}},[_c('el-input',{ref:`input${index}`,refInFor:true,attrs:{"disabled":_vm.submitting,"clearable":"","placeholder":"请输入"},model:{value:(item.name.en),callback:function ($$v) {_vm.$set(item.name, "en", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.name.en"}})],1)],1),_c('el-col',{attrs:{"sm":24,"md":6}},[_c('el-form-item',{attrs:{"label-position":"left","label":"父级","prop":'items.' + index + '.parentId',"rules":[
                  {
                    required: false,
                    message: '请选择',
                    trigger: 'blur',
                  },
                ]}},[_c('FaqTagCascader',{model:{value:(item.parentId),callback:function ($$v) {_vm.$set(item, "parentId", $$v)},expression:"item.parentId"}})],1)],1),_c('el-col',{attrs:{"sm":24,"md":6}},[_c('el-form-item',{attrs:{"label":"次序(数值越大越靠前)","prop":'items.' + index + '.seq',"rules":[
                  { required: true, message: '请输入次序', trigger: 'blur' },
                ]}},[_c('el-input',{ref:`input${index}`,refInFor:true,attrs:{"disabled":_vm.submitting,"clearable":"","placeholder":"请输入","type":"number"},model:{value:(item.seq),callback:function ($$v) {_vm.$set(item, "seq", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.seq"}})],1)],1)],1)],1)],1)}),1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"disabled":_vm.submitting},on:{"click":_vm.handleReset}},[_vm._v("重 置")]),_c('el-button',{attrs:{"disabled":_vm.submitting},on:{"click":_vm.addRow}},[_vm._v("新增一行")]),_c('el-button',{attrs:{"type":"primary","loading":_vm.submitting},on:{"click":function($event){return _vm.submitForm('form')}}},[_vm._v(_vm._s(_vm.submitting ? "保存中..." : "保 存"))])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }